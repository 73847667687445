@mixin font-family-bundle($name, $font, $fontbold) {
  @font-face {
    font-weight: normal;
    src: url($font) + ' ' + getFormat($font);
    font-family: $name;
    font-display: swap;
  }
  @font-face {
    font-weight: bold;
    src: url($fontbold) + ' ' + getFormat($fontbold);
    font-family: $name;
    font-display: swap;
  }
}

@function getFormat($src) {
  @if (str-index($src, '.woff2')) {
    @return 'format("woff2")';
  }
  @if (str-index($src, '.woff')) {
    @return 'format("woff")';
  }
  @if (str-index($src, '.ttf')) {
    @return 'format("truetype")';
  }
  @if (str-index($src, '.otf')) {
    @return 'format("opentype")';
  }
  @if (str-index($src, '.eot')) {
    @return 'format("embedded-opentype")';
  }
  @return '';
}

// FONT WEIDGHT REFERENCES
// 100 - Thin
// 200 - Extra Light
// 300 - Light
// 400 - Regular
// 500 - Medium
// 600 - Semi Bold
// 700 - Bold
// 800 - Extra Bold
// 900 - Black
// 950 - Extra Black

// INSTRUCTONS TO ADD A NEW FONT:
// 1. Add the font to the CDN (https://cdnstatic.usheru.com/fonts/registered/) in the right format:
//  - lowercase
//  - replace spaces with -
// 2. Add the font weights to the getFontWeight function.
// 3. Add the font to the getFontExtension function.
// 4. Add the reference to the "Listed Fonts" section of the mixin documentation.
//  - Respect the alphabetical order of the fonts

/**
 * @name: Name of a registered font
 * @style: Font style (normal, italic, oblique) if available
 *
 * Ej: 
 * @include font-family(montserrat);
 * @include font-family(montserrat, italic);
 * 
 * Listed Fonts:
 * - montserrat                 - 100, 200, 300, 400, 500, 600, 700, 800, 900
 * - montserrat | italic        - 100, 200, 300, 400, 500, 600, 700, 800, 900
*/
@mixin font-family($name, $style: normal) {
  $URL_BASE: 'https://cdnstatic.usheru.com/fonts/registered/';
  $extension: getFontExtension($name);
  $format: getFormat($extension);
  $weights: getFontWeight($name, $style);

  @each $weight in $weights {
    $URL: $URL_BASE + $name + '-' + $weight;
    @if ($style == normal) {
      $URL: $URL + $extension;
    } @else {
      $URL: $URL + '-' + $style + $extension;
    }

    @font-face {
      font-style: $style;
      font-weight: $weight;
      src: url('#{$URL}') #{$format};
      font-family: $name;
      font-display: swap;
    }
  }
}

@function getFontWeight($font, $style) {
  @if ($font == 'montserrat' and $style == normal) {
    @return 100, 200, 300, 400, 500, 600, 700, 800, 900;
  }

  @if ($font == 'montserrat' and $style == italic) {
    @return 100, 200, 300, 400, 500, 600, 700, 800, 900;
  }
}

// Used in font-family mixin
@function getFontExtension($name) {
  // .ttf
  @if ($name == 'montserrat') {
    @return '.ttf';
  }
}

// Used in font-family mixin
@function getFormat($extension) {
  @if ('.woff2') {
    @return 'format("woff2")';
  }
  @if ('.woff') {
    @return 'format("woff")';
  }
  @if ('.ttf') {
    @return 'format("truetype")';
  }
  @if ('.otf') {
    @return 'format("opentype")';
  }
  @if ('.eot') {
    @return 'format("embedded-opentype")';
  }
  @return '';
}
