@import 'media-query.mixins';
@import 'buttons.mixins';

// Makes the scrollPositionRestoration without the smooth scroll
:root {
  scroll-behavior: auto;
}

html {
  scroll-behavior: smooth;
}

/* You can add global styles to this file, and also import other style files */
/* ========== GLOBALS =========== */
body {
  --_bodyBackground: var(--backgroundColor);

  margin: var(--header-height-mobile, var(--_header-height-mobile)) 0 0 0;
  //@include scrollbars();
  // @include for-tablet-landscape-up {
  //   margin-top: var(--header-height);
  // }

  background: var(--bodyBackground, var(--_bodyBackground));
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;

  line-height: 1.2;

  @include for-tablet-landscape-up {
    background-size: cover;
  }

  * {
    outline: none;
  }

  /* ========== COMMONS =========== */
  .page-content {
    margin: 0 auto;
    width: var(--contentWidth);
    max-width: var(--maxContentWidth, #{$xxl});
  }

  /* ========== TEXT =========== */

  .text-danger {
    color: red;
  }

  .tab-msg {
    color: var(--textColor);
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  p {
    -webkit-text-size-adjust: none;
  }

  .disabled,
  .swiper-button-disabled {
    opacity: var(--swiper-item-disabled-opacity, 0.4);
    pointer-events: none;
  }

  .title {
    padding: var(--titlePadding);
    color: var(--titleColor);
    font-weight: var(--titleFontWeight);
    font-size: var(--titleSizeMobile);
    text-transform: var(--titleTextTransform);

    @include for-tablet-landscape-up {
      font-size: var(--titleSize);
    }
  }

  .subtitle {
    padding: var(--subtitlePadding);
    color: var(--subtitleColor);
    font-weight: var(--subtitleFontWeight);
    font-size: var(--subtitleSizeMobile);
    line-height: var(--subtitleLineHeight);
    text-transform: var(--subtitleTextTransform);

    @include for-tablet-landscape-up() {
      font-size: var(--subtitleSize);
    }
  }

  .text {
    font-size: var(--text-font-size, 1rem);
  }

  ul.info-list {
    margin: 0;
    padding: var(--infoListPadding);
    list-style: var(--infoListStyle);

    li {
      display: flex;
      column-gap: var(--info-list-item-column-gap);
      row-gap: var(--info-list-item-row-gap);
      flex-direction: var(--infoListItemMarginFlexDirection);
      margin: var(--infoListItemMargin, 0 0 10px 0);

      .subtitle {
        display: flex;
        width: var(--inforListSubtitleMinWidth);
        min-width: var(--inforListSubtitleMinWidth);
      }
    }

    .semicolon {
      display: var(--infoListSemiColonDisplay);
    }

    .comma {
      display: var(--infoListCommanDisplay);
    }

    li:last-child {
      margin: var(--infoListItemLastChildMargin, 0);
    }
  }

  .button,
  .p-button {
    @include button-primary;
    box-shadow: none !important;
  }

  .button-secondary {
    @include button-secondary;
  }

  .slider-button {
  }

  .cta-button {
  }

  .view-all-button {
  }

  .watch-trailer-button {
  }

  .see-more-btn {
  }

  /* ========== COMPONENTS =========== */

  ng-progress {
    .ng-bar {
      background-color: var(--progress-bar-color, var(--brandColor)) !important;
      height: var(--progress-bar-height, 2px) !important;
    }

    .ng-spinner-icon {
      border-top-color: var(--progress-bar-color, var(--brandColor)) !important;
      border-left-color: var(--progress-bar-color, var(--brandColor)) !important;
    }

    .ng-meteor {
      box-shadow:
        var(--brandColor, --brandColor-hover) 0px 0px 10px,
        var(--brandColor, --brandColor-hover) 0px 0px 5px !important;
    }
  }

  .channel-slide {
    .video-thumbnail {
      .ctrl-play.center,
      .load-more-movie-detail {
        display: none !important;
      }
    }
  }

  ush-video-fullscreen {
    .video-container {
      background-color: black !important;

      @media all and (orientation: portrait) {
        display: flex;
        align-items: center;

        video {
          height: auto;
        }
      }
    }
  }

  /* ========== COMPONENTS - LAYOUT =========== */

  .page-wrapper {
    display: flex;
    flex-direction: column;
    padding: var(--page-wrapper-padding, 0);
    min-height: calc(100vh - var(--header-height-mobile));
    overflow: hidden;

    .page-content-wrapper {
      flex: 1 0 auto;
    }

    @include for-tablet-landscape-up {
      min-height: calc(100vh - var(--header-height));
    }
  }

  /* ========== FOOTER =========== */
  ush-footer {
    flex-shrink: 0;
  }

  #footer {
    a {
      text-decoration: none;
    }
  }

  /* ========== HEADER =========== */
  /* ========== COMPONENTS - PAGE =========== */
  // synopsis
  ush-movie-synopsis {
    p {
      margin: 0;
      padding: 0;
    }
  }

  /* ========== ABOUT PAGE =========== */
  /* ========== AUTH PAGE =========== */
  /* ========== BRANDED PAGE =========== */
  .branded-page {
    .menu {
      a {
        max-width: 300px;
      }
    }
  }

  /* ========== JOIN COMMUNITY PAGE =========== */
  /* ========== MOVIES PAGE =========== */
  ush-movies-page {
    #input-movie-cast {
      width: 100%;
    }
  }

  /* ========== MOVIE DETAIL PAGE =========== */
  ush-video-thumbnail.main-video-thumbnail {
    .video-thumbnail {
      img {
        width: 100vw;
        height: 100%;
        max-height: calc(100vh - var(--header-height));
        object-fit: cover;
      }
    }
  }

  ush-movie-detail-page {
    .header-msg {
      display: var(--header-msg-display, block);
      padding: var(--movieDetailTitlesPadding);
      color: var(--movieDetailTitlesColor);
    }
  }

  /* ========== CAROUSEL ================ */
  .carousel__wrapper {
    display: var(--carousel-wrapper-display, flex);
    grid-template-columns: repeat(2, auto) 1fr repeat(2, auto);
    grid-auto-rows: min-content;
    column-gap: 20px;
    row-gap: 10px;
    flex-direction: column;
    // align-items: center;

    @media all and (min-width: $md) {
      display: grid;
    }

    .carousel__title {
      display: flex;
      column-gap: 20px;
      row-gap: 5px;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      width: max-content;
      width: var(--carousel-title-width);
      max-width: 100%;
    }

    .carousel__title:not(h2) {
      grid-row: var(--carousel-title-grid-row, 1);
      grid-column: var(--carousel-title-grid-column, 1);
      justify-content: var(--carousel-title-justify-content, flex-start);
      align-self: var(--carousel-title-align-self, unset);
      justify-self: var(--carousel-title-justify-self, unset);
      margin: var(--carousel-title-margin, 0);

      & shared-location-widget-carousels {
        align-self: var(--carousel-location-align-self, unset);
      }
    }

    .carousel__title:is(h2) {
      color: var(--carousel-title-color, inherit);
      font-weight: var(--carousel-title-font-weight, bold);
      font-size: var(--carousel-title-font-size, var(--font-size-160, 1.6rem));
      text-transform: var(--carousel-title-text-transform, uppercase);
    }

    .carousel__view-all {
      grid-row: var(--carousel-view-all-grid-row, 1);
      grid-column: var(--carousel-view-all-grid-column, 2);
      align-self: var(--carousel-view-all-align-self, center);
      justify-self: var(--carousel-view-all-justify-self, unset);
    }

    .carousel__content {
      position: relative;
      grid-row: 2 / 3;
      grid-column: 1 / 6;
    }

    .carousel__arrow {
      position: relative;
      grid-row: var(--carousel-arrow-grid-row, 1);
      align-self: var(--carousel-arrow-align-self, center);
      z-index: var(--carousel-arrow-z-index, 10);
    }

    .carousel__left-arrow {
      grid-column: var(--carousel-arrow-left-grid-column, 4);
    }

    .carousel__right-arrow {
      grid-column: var(--carousel-arrow-right-grid-column, 5);
    }

    h2 {
      margin: 0;
    }

    .swiper-button-disabled {
      opacity: 0.2;
    }

    .carousel__content {
      display: block;
    }

    :host ::ng-deep {
      ush-arrow {
        i {
          font-size: 30px;
        }
      }
    }

    .carousel__left-arrow,
    .carousel__right-arrow {
      display: none;

      @media all and (min-width: $md) {
        display: block;
      }
    }

    .error-msg {
      padding: 30px;
      color: var(--carousel-error-color, var(--textColor));
      text-align: center;
    }

    swiper-container::part(scrollbar) {
      position: relative;
      margin-top: 10px;

      @media all and (min-width: $md) {
        display: none;
      }
    }
  }

  .slider-container {
    .video-thumbnail-title {
      font-size: var(--videoThumbnailPageContentSliderFontSize) !important;
    }

    .controls {
      top: unset !important;
      transform: translate(-50%, 0) !important;
    }
  }

  #channel-carousel {
    position: relative;

    .slide-wrap {
      cursor: auto !important;
    }
  }

  /* ========== BRANDED PAGE =========== */
  .branded-page {
    .uplay-container {
      align-items: center !important;

      .items-container {
        margin-bottom: 20px;
      }
    }
  }

  /* ========== SPECIAL PAGE =========== */

  // Arrow
  .arrow-long {
    position: relative;
    background: var(--arrowColor);
    width: 70px;
    height: 2px;
  }

  .left {
    transform: rotate(180deg);
  }

  .arrow-long:after {
    display: block;
    position: absolute;
    right: 8px;
    transform: rotate(45deg);
    margin-top: -3px;
    border-right: 2px solid var(--arrowColor);
    height: 25px;
    content: '';
  }

  .arrow-long:before {
    display: block;
    position: absolute;
    right: 8px;
    bottom: 100%;
    transform: rotate(315deg);
    margin-bottom: -5px;
    border-right: 2px solid var(--arrowColor);
    height: 25px;
    content: '';
  }

  .arrow-channel-slide-left,
  .arrow-channel-slide-right {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    cursor: pointer;
    width: 90px;
    height: 30px;
  }

  .arrow-channel-slide-left {
    &:hover,
    &:focus {
      transform: scale(1.2) rotate(180deg);
    }
  }

  .arrow-channel-slide-right {
    &:hover,
    &:focus {
      transform: scale(1.2);
    }
  }

  .arrows-top-side {
    display: flex;
  }

  .caruosel-ctrls {
    display: flex;

    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: var(--transitionsTime);
      cursor: pointer;
      margin-left: 5px;
      border: var(--carouselControlsArrowBorder);
      width: var(--carouselControlsArrowWidth);
      height: var(--carouselControlsArrowHeight);
      color: #b4b4b4;
    }
  }

  .transparent-background {
    background-color: transparent !important;
  }

  .p-dropwdown.p-focus {
    box-shadow: none;
    border: none;
  }

  .no-pointer {
    pointer-events: none;
  }

  // FIX GOOGLE PLACES AUTOCOMPLETE

  .pac-container {
    z-index: 999999999 !important;
  }
}

// NEWS GLOBAL

.ql-size-huge {
  font-size: var(--ql-size-huge, 2rem);
}

.ql-size-large {
  font-size: var(--ql-size-large, 1.5rem);
}

.ql-size-small {
  font-size: var(--ql-size-large, 0.6rem);
}

.news-page {
  --ql-indent: 4rem;
}

.ql-indent-1 {
  text-indent: calc(var(--ql-indent) * 1);
}

.ql-indent-2 {
  text-indent: calc(var(--ql-indent) * 2);
}

.ql-indent-3 {
  text-indent: calc(var(--ql-indent) * 3);
}

.ql-indent-4 {
  text-indent: calc(var(--ql-indent) * 4);
}

.ql-indent-5 {
  text-indent: calc(var(--ql-indent) * 5);
}

.ql-indent-2 {
  text-indent: 8rem;
}

.ql-align-center {
  text-align: center;
}

.ql-align-end {
  text-align: end;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

blockquote {
  position: relative;
  text-indent: 4rem;

  &::before {
    position: absolute;
    left: 0;
    background-color: black;
    width: 3px;
    height: 100%;
    content: '';
  }
}

.ql-video {
  aspect-ratio: var(--ql-video-aspect-ratio, 16 / 9);
  width: var(--ql-video-width, 100%);
  width: var(--ql-video-height, 100%);
}

ush-showtime-streaming-in-cinemas {
  .p-accordion .p-accordion-content {
    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
  }
}

// FIX VIMEO CAROUSEL CHANNEL

#channel-carousel ush-vimeo-player iframe {
  position: static;
}

.p-panel .p-panel-content {
  background-color: transparent;
}

// FORM FIELDS
.form-field {
  label:first-letter {
    text-transform: capitalize;
  }
}

.form-field__mandatory {
  &::after {
    margin: 0 2px;
    content: '*';
    color: red;
  }
}
